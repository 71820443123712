@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

input:focus-visible {
  outline: 0 !important;
}

#scrollbar-custom::-webkit-scrollbar {
  width: 12px;
}

#scrollbar-custom::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #D6DCE7;
  border: 1px solid #D6DCE7;
}

#scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #98A2B3;
}

/* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  /* Background of the scrollbar track */
  background: none;
  /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 99px;
  /* Rounded corners for the thumb */
  /* Padding around thumb and matching the track background */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  /* Thin scrollbar width */
  scrollbar-color: #888 #f1f1f1;
  /* Thumb and track colors */
}